@import url('https://fonts.googleapis.com/css2?family=Abel&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans";
  }
}

:root {
  font-family: "DM Sans", Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color-scheme: light dark;
  text-color: #000;
  background-color: #f4f4f4;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: inherit;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  min-width: 320px;
  min-height: 100%;
  max-height: 100%;
}
